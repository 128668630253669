<template>
  <div class="pay-view">
    <div class="pay-head"
          :style="{
			'height':headH*2+'rpx',
			'padding-top':headPTop*2+'rpx',
			'line-height':headH*2+'rpx',
		}"
    >{{shop_name}}</div>
    <div class="pay-info">
      <div class="pay-info-input">
        <div class="info-text">消费总额</div>
        <div class="info-input">￥ {{totalNum}}</div>
        <div class="info-text">可询问工作人员应缴费用总额</div>
      </div>
      <div class="pay-info-sumit" @click="sumbit()">支付</div>
    </div>
    <div class="pay-num">
      <div class="pay-num-input" @click="setNum(item)" v-for="(item,i) in numList" :key="i">{{item}}</div>
      <div class="pay-num-input" @click="delNum()">
        <div class="pay-num-del">
          <div class="pay-num-del-wrap">x</div>
        </div>
      </div>
    </div>
    <div v-html="zfbUrl"></div>
  </div>
</template>

<script>
import {getShopOrderIndex,getShopOrderCreatPay} from '@/api/pay.js'

import {
  getPhoneTopHeight,getNavHeight
} from "@/common/js/utils";
import tools from "@/common/js/tools";
export default {
  data(){
    return{
      shop_name:'',
      shop_id:'',
      headH:0,
      headPTop:0,
      numList:['1','2','3','4','5','6','7','8','9','.','0'],
      totalNum:'',
      isAJAX:false,
      isWeiXin:false,
      zfbUrl:'',
      shop_code:'',
    }
  },
  created() {},
  mounted() {
    this.shop_code=this.$route.query.shop_code
    if(!this.shop_code){
      this.$router.push({path:'/home'})
    }else {
      // #ifdef H5
      this.isWeiXin = tools.isWx();
      if (this.isWeiXin) {
        tools.wxInitialize(["chooseWXPay"]);
      }
      // #endif

      this.headH = getNavHeight();
      this.headPTop = getPhoneTopHeight();
      getShopOrderIndex({shop_code:this.shop_code}).then((res)=>{
        if(res.code === 1){
          this.shop_id = res.data.id;
          this.shop_name = res.data.shop_name;
        }else {
          // this.$router.push({path:'/home'})
        }
      })
    }

  },
  methods:{
    setNum(item){
      this.totalNum += item
    },
    delNum(){
      if(this.totalNum){
        this.totalNum = this.totalNum.slice(0,this.totalNum.length-1)
      }else{
        tools.error('请输入金额')
      }
    },
    sumbit(){
      if(!this.totalNum||this.totalNum*1 <= 0){
        return tools.error('请输入金额')
      }
      let reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
      if(!reg.test(this.totalNum)){
        return tools.error('金额格式错误')
      }
      let pay_type //1：微信，2：支付宝
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger"){
        pay_type = 1
      }else{
        pay_type = 2
      }
      if(this.isAJAX){
        return
      }
      this.isAJAX = true
      getShopOrderCreatPay(
        {shop_id:this.shop_id,
          pay_money:this.totalNum *1,
          pay_type:pay_type}).then(async (res) => {
        if (res.code === 1) {
          if (pay_type === 1) {
            this.isAJAX = false
            if (res.data.wx_pay) {

              const ret = await tools.weiXinPay(res.data.wx_pay);
              if (ret) {
                this.payOk(res.data.wx_pay.rs_sn)
              } else {
                tools.error('支付失败');
                this.isAJAX = false
              }
            } else {
              tools.error("支付生成失败");
              this.isAJAX = false
            }
          } else {
            if (res.data.zfb_pay) {
              this.isAJAX = false
              this.zfbUrl = res.data.zfb_pay;
              setTimeout(function () {
                document.forms["alipaysubmit"].submit();
              }, 50);
            } else {
              tools.error("支付生成失败");
            }
          }
        } else {
          this.isAJAX = false
        }
      })
    },
    payOk(rs_sn) {
      this.$router.push({path:'/pay-ok', query: {
          payMoney: this.totalNum,
          shop_id: this.shop_id,
          rs_sn: rs_sn,
        },})
    },

  },
}
</script>


<style scoped lang="less">
.pay-view{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .pay-head{
    font-size: 40rpx;
    font-weight: 600;
    text-align: center;
    background-color: #fff;
  }
  .pay-info{
    flex: 1;
    overflow: auto;
    box-sizing: border-box;
    padding: 20rpx;
    .pay-info-sumit{
      margin-top: 30rpx;
      width: 100%;
      height: 90rpx;
      line-height: 90rpx;
      text-align: center;
      border-radius: 200rpx;
      background: linear-gradient(to right, #FE9387, #FB604E, #F83820);
      color: #fff;
      font-size: 32rpx;
    }
    .pay-info-input{
      width: 100%;
      height: 330rpx;
      background-color: #fff;
      box-sizing: border-box;
      padding: 20rpx;
      .info-text{
        font-size: 30rpx;
        color: #aaa;
      }
      .info-input{
        width: 100%;
        height: 100rpx;
        line-height: 100rpx;
        margin: 36rpx 0;
        padding-bottom: 20rpx;
        border-bottom: 1rpx solid #E4E4E4;
        font-size: 32rpx;
        font-weight: 600;
      }
    }
  }
  .pay-num{
    width: 100%;
    height: 600rpx;
    background: rgb(208, 211, 220);
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    box-sizing: border-box;
    padding: 30rpx;
    .pay-num-input{
      width: 215rpx;
      height: 110rpx;
      background-color: #fff;
      border-radius: 16rpx;
      text-align: center;
      line-height: 110rpx;
      font-size: 40rpx;
      font-weight: bold;
    }
    .pay-num-del {
      margin-top: 38rpx;
      box-sizing: border-box;
      padding-left: 25rpx;
      line-height: 35rpx !important;
      font-weight: 400 !important;
      &-wrap {
        margin: 0 auto;
        width: 40rpx;
        height: 40rpx;
        background-color: #dedede;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: -40rpx;
          border: 40rpx solid transparent;
          border-right: 40rpx solid #dedede;
        }
      }
    }
  }
}
</style>

